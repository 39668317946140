import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import { Waypoint } from 'react-waypoint';
import magnify from '../../content/assets/search.svg'
import filter from "../../content/assets/filter.svg"
import close from "../../content/assets/x.svg"
import uniq from 'lodash/uniq';

export default () => {

const [isOpen, setIsOpen] = useState(false);
const determine = ({previousPosition, currentPosition }) => {
    if ( currentPosition ===  Waypoint.above ) {
      setIsOpen(!isOpen);
    } 
    if ( previousPosition ===  Waypoint.above ) {
      setIsOpen(!isOpen);
    }
}


const [search, setSearch] = useState('');
const onFousSearch = (e) => {
    e.preventDefault(); 
    setSearch('');
}
const updateSearch = (e) => {
    setSearch( e.target.value.substr(0,40) );
}

const onOrganisation = (e) => {
    e.preventDefault(); 
    var selected = e.target.innerHTML
    setSearch( selected );
}

const reduceSearch = (e) => {
  e.preventDefault(); 
  e.target.innerHTML.trim() === 'All' ? setSearch('') : setSearch(e.target.innerHTML.trim())
}



const data = useStaticQuery(graphql`
    query SugardirectoryQuery {
        allSugardirectoryJson {
    edges {
      node {
          id
          organisation
          region
          division
          title
          telephone
          website
          physical
          postal
          name
          email
          cell
       
      }
    }
  }
  }
  `)

  const directory = data.allSugardirectoryJson.edges;
  let newdirectory = directory.filter( ( {node} ) =>  {
    if ( node  ) {
      return node.organisation.toLowerCase().includes(search.toLowerCase()) || node.division.toLowerCase().includes(search.toLowerCase()) || node.region.toLowerCase().includes(search.toLowerCase()) || node.title.toLowerCase().includes(search.toLowerCase()) || node.name.toLowerCase().includes(search.toLowerCase()) 
    }  else { return node }
  });


 
  let list = directory.map( ({ node }) =>  { return node.division }  )
  const uniqList = uniq(list).sort();
  const [isList, setIsList] = useState(false);

  const toggleList = () => {
    setIsList(!isList);
  }

 
  return ( 
    <Layout>


    <div className="w-100 cf">
    <div className="ph3 ph4-ns pv1">
    <div className="mw9 center">
          <div className="flex nowrap  overflow-hidden" >
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib">SASA</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib">SACGA</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib">SAFDA</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib" >SASMA</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib ttu">Milling and Refining</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib ttu">Research</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib ttu">Labour</div>
              <div onClick={onOrganisation} className="pv2 pointer f7 f6-ns dim link-black mr2 mr3-m mr4-l dib ttu">Insurance</div>
          </div>
    </div>
    </div>
    </div>
      



    <section className="w-100 bg-white cf bt bb b--black-05 relative">
    <div className="ph3 ph4-ns pv1">
    <div className="mw9 center cf">

          <div className="w-100  h3">
            <div  className={ isOpen ? 'animated slideInDown fixed top-0 left-0 w-100 bt bb b--black-05 ph3 ' : 'db' } >
              <div id="searchbar" className="w-100  flex bg-white">
                <div className="w-100 flex items-center ">
                  
                  <img onClick = {onFousSearch} className="square-1-5 mr4 pointer dim" src={magnify} alt="search icon"/>
                    <input  
                    id="searchInput"
                    value={search}
                    onChange = {updateSearch} 
                    onClick = {onFousSearch}
                    className="w-100 bn h3 " type="search" placeholder="Search by Region, Division, Title, or Name"  />
                    <img onClick = {toggleList} className="square-2-5 pointer dim" src={filter} alt='menu'/> 

                </div>
            </div>
            </div>
          </div>

                <div  className={ isList ? 'shadow-5  fixed top-0 right-0 bottom-0 vh-100 overflow-y-scroll momentum-scroll overflow-hidden  animated bg-white slideInRight z-9999 faster  w-100 w-third-ns  ' : 'dn' } >
                  <div className="w-100  ph4  bg-white  bl b--black-05">
                    <ul className="list pa0 ma0 pb4 br2-ns ">
                      <li className="f6 fw5 pt4 ph3-ns theme-menu ttu tracked-lg" onMouseUp={toggleList} >filter by category</li>
                      <li className="f6 fw5 pt4 ph3-ns" onMouseUp={toggleList} ><img className="square-1 fr pointer dim" src={close} alt='menu'/> </li>
                      <li className="pointer ph3-ns ph0-ns pt3"> <div className="f6 fw4 link black-70 pointer ttc" onClick={reduceSearch} onMouseUp={toggleList}>All</div></li>
                      { uniqList.map(
                        (x) =>  <li key={x} className={"ph3-ns pv2 "}> <div  className="f6 pointer link black-70 fw4 ttc dim " onClick={reduceSearch} onMouseUp={toggleList}> {x} </div> </li>  )
                      }
                     <li className="h4 cf "></li>
                    </ul> 
                  </div>
                </div>



      </div>
      </div>
      </section>






    <div className="w-100 cf">
    <div className="ph3 ph4-ns pv1">
    <div className="mw9 center">

    <div className="w-100 f4 fw5 pb2  pt4"></div>

    <Waypoint onPositionChange={determine}  /> 


    <div className="w-100 fl pb4 cf mb6">   
        <div className="w-100 ph0 pt0 pt3-ns pb8">
            {newdirectory.map(({ node }) => 
            <div className="w-100  w-90-l pr3-ns pb3 cf" key={node.id}>
                <div className="w-100 mw-100 bg-white pv3 bb b--black-10">
                { node.division ? <div className="flex"> <div className="f5 fw5 black-80 ttc w-90 pb1"> {node.division} </div> </div> : ''}  
                { node.region ? <div className="flex"> <div className="f6 tracked-sm fw4 black-60 ttc pb2"> {node.region} </div> </div> : ''}  
                { node.title ?  <div className="flex"> <div className="f6 fw5 black-60 ttc pb1 tracked-sm"> {node.title} </div></div> : ''}   
                { node.name ? <div className="flex"> <div className="f6 fw5 black pb1 ttc pb2 tracked"> {node.name} </div></div> : ''}                         
                { node.telephone ? <div className="flex"> <div className="f6 fw4 black-60 ttc pb1"> <a title="Call" href={`tel:${node.telephone}`}> {node.telephone} </a></div></div>  : ''}  
                { node.cell ? <div className="flex"> <div className="f6 fw4 black-60 ttc pb1 "> <a title="Call" href={`tel:${node.cell}`}> {node.cell} </a> </div></div>  : ''}  
                { node.email ? <div className="flex"><div className="f6 fw4 black-60 pb1 "> <a href={`mailto:${node.email}`}>{node.email}</a> </div></div>  : ''} 
                { node.website ? <div className="flex"><div className="f6 fw4 black-60 pb1"> {node.website} </div></div>  : ''} 
                { node.physical ? <div className="flex"><div className="f6 fw4 black-60 pb1"> {node.physical} </div></div>  : ''}   
                { node.postal ? <div className="flex"><div className="f6 fw4 black-60 pb1"> {node.postal} </div></div>  : ''}                          
                </div>
            </div>
            )}
        </div> 
    </div> 
      
    </div>
    </div>
    </div>
    </Layout>
  ) 

}